export default {
  colors: {
    background: '#ffffff',
    text: '#101B3A',
    primary: '#000081',
    secondary: '#F61D31',
    tertiary: '#E4E5E6',
    highlight: '#5B8BF7',
  },
  fonts: {
    main:
      '"Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  sizes: {
    maxWidth: '1272px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}
